import * as React from "react";
import { useEffect } from "react";
import "./faq.scss";
import { parseHTML } from "../../helpers/parseHTML"
import {
  SEOMetaTags,
  TitleSection,
  CallToAction,
  GreyFooter,
  FundingBanner,
} from "../../components";
import MainMenu from "../../components/MainMenu/MainMenu";
import { IFAQs } from "../interfaces";

const FAQs = (data: IFAQs): JSX.Element => {

  useEffect(() => {
    const bodyStyle = document.body.style;
    bodyStyle.opacity = "1";
    bodyStyle.transition = "opacity 0.3s";
  }, []);

  const { items, faq, seoMetaTags, menu, lang, footer, cta, c2c, pageTitle, banner, urlName } = data.pageContext;
  const pageTitleData = pageTitle && pageTitle.find(el => el.page === "faq");
  const pageName = pageTitleData && pageTitleData.name;
  const description = pageTitleData && pageTitleData.description;

  const allSlugLocales = faq && faq._allSlugLocales;
  const alternateLocaleData = allSlugLocales && allSlugLocales.filter(el => el.locale !== lang);
  const alternateLinks = alternateLocaleData && alternateLocaleData.map(el => el && {
    locale: el.locale,
    link: `https://pushpushgo.com/${el.locale}/${urlName}/${el.value}/`,
  });

  return (
    <>
      <MainMenu menu={menu} lang={lang} c2c={c2c}/>
      <SEOMetaTags lang={lang} tags={seoMetaTags} pageName={pageName} alternateLinks={alternateLinks}/>
      <TitleSection propertyReference={[{ key: "title", value: `${pageName}` }, {
        key: "blog",
        value: "blog-version",
      }, { key: "color", value: "green-version" }, { key: "paragraph", value: `${description}` }]}/>
      <div className="content-limiter faqs-container">
        <div className="faqs-questions">
          {items.map(faqQuestion => {
            return <a key={Math.random() + new Date().toDateString()}
                      className={` ${faqQuestion.category.name === faqQuestion.title ? "category" : ""} 
                      ${faqQuestion.slug === faq.slug ? "active" : ""}`}
                      href={`/${lang}/faq/${faqQuestion.slug}`}>{faqQuestion.title}</a>;
          })}
        </div>
        <div className="faqs-answers">
          <div>{parseHTML(faq.content)}</div>
        </div>
      </div>
      <CallToAction cta={cta}/>
      <GreyFooter footer={footer} lang={lang}/>
      <FundingBanner banner={banner}/>
    </>
  );
};

export default FAQs;
